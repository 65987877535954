@import '../../../Styles/tokens';
@import '../../../Styles/theme_customer.scss';

.section {
  padding: 70px 0px;
  background: $theme-first-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: $mobile-max-width) {
    padding: 50px 0px;
  }
}

.slider {
  display: flex;
  gap: 64px;
  position: relative;
  z-index: 2;
  max-width: 100vw;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  transform: translateY(100px);
  opacity: 0;
  transition: 1s;
  padding: 0px 60px;
  margin-bottom: 30px;
  @media screen and (max-width: $mobile-max-width) {
    padding: 0px 20px;
    gap: 24px;
    margin-bottom: 10px;
  }

  .slide {
    display: flex;
    border-radius: 8px;
    min-width: 400px;
    position: relative;
    align-items: flex-end;
    flex-direction: column;
    @media screen and (max-width: $mobile-max-width) {
      min-width: 240px;
    }
    .slidePic {
      display: block;
      min-width: 400px;
      height: 545px;
      border-radius: 8px;
      object-fit: cover;
      @media screen and (max-width: $mobile-max-width) {
        min-width: 240px;
        height: 340px;
      }
    }

    .slide__subcategory {
      font-size: 13px;
    }

    .slide__categoty {
      position: absolute;
      top: 24px;
      left: 24px;
      color: #fff;
      font-family: 'Open Sans';
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      z-index: 6;
      line-height: 120%;
      padding: 16px !important;
      border-radius: 8px;
      background: $cblue;
      @media screen and (max-width: 960px) {
        font-size: 16px;
        padding: 12px !important;
        top: 10px;
        left: 10px;
      }
      @media screen and (max-width: 320px) {
        font-size: 14px;
      }
    }
    .slide__desc {
      border-radius: 8px;
      background: #1d1d1d;
      width: 350px;
      padding: 16px;
      margin: auto;
      position: relative;
      z-index: 10;
      margin-top: -30%;
      @media screen and (max-width: $mobile-max-width) {
        width: 225px;
        padding: 12px;
      }
      .slide__text {
        color: #f3f3f3;
        font-family: 'Open Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
        margin-bottom: 16px;
        @media screen and (max-width: $mobile-max-width) {
          font-size: 12px;
        }
      }
      .slide__author {
        display: flex;
        justify-content: flex-start;
        gap: 16px;
        align-items: center;
        @media screen and (max-width: $mobile-max-width) {
          gap: 12px;
        }
        .authorPic {
          display: block;
          width: 40px !important;
          height: 40px;
          border-radius: 50%;
          @media screen and (max-width: $mobile-max-width) {
            width: 30px;
            height: 30px;
          }
        }
        p {
          color: #f3f3f3;
          font-family: Open Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          width: 300px;
          @media screen and (max-width: $mobile-max-width) {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.textTop {
  font-family: 'Montserrat';
  font-size: 90px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  color: #f5f5f5;
  position: relative;
  z-index: 0;
  margin-bottom: 50px;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s;
  @media screen and (max-width: $mobile-max-width) {
    font-size: 40px;
    margin-bottom: 25px;
  }
  @media screen and (max-width: 320px) {
    font-size: 36px;
  }
}

.textBtm {
  font-family: 'Montserrat';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  color: #f5f5f5;
  position: relative;
  z-index: 0;
  transform: translateY(60px);
  opacity: 0;
  transition: 1s;
  margin-top: 15px;
  @media screen and (max-width: $mobile-max-width) {
    font-size: 30px;
  }
  @media screen and (max-width: 320px) {
    font-size: 36px;
  }
}

.date {
  font-size: 11px !important;
  font-weight: 600 !important;
}
