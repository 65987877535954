@import '../../../Styles/tokens';

.section {
  background: $theme-first-background;
  padding-top: 220px;
  padding-bottom: 75px;
  @media screen and (max-width: $mobile-max-width) {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}
.sectionH1 {
  display: flex;
  justify-content: space-between;
  max-width: $m1470;
  padding: $m1470p;
  position: relative;
  margin: auto;
  margin-bottom: 160px;
  @media screen and (max-width: $mobile-max-width) {
    margin-bottom: 60px;
  }
}

.sectionH1__left {
  max-width: 645px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  h1 {
    color: $cblue;
    font-size: $fbig;
    font-family: 'Montserrat';
    font-weight: 700;
    line-height: 100%;
    transform: translate(0, 40px);
    opacity: 0;
    transition: 0.5s ease;
    @media screen and (max-width: $mobile-max-width) {
      font-size: 32px;
    }
    @media screen and (max-width: 320px) {
      font-size: 28px;
    }
  }

  p {
    font-size: 24px;
    color: $theme-first-background-opposite;
    font-family: 'Montserrat';
    font-weight: 400;
    line-height: 120%;
    transform: translate(0, 40px);
    opacity: 0;
    transition: 0.5s ease;
    transition-delay: 0.3s;
    @media screen and (max-width: $mobile-max-width) {
      font-size: 18px;
    }
    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }
  .sectionH1__btns {
    display: flex;
    gap: 35px;
    transform: translate(0, 40px);
    opacity: 0;
    transition: 0.5s ease;
    transition-delay: 0.7s;
    @media screen and (max-width: $mobile-max-width) {
      gap: 10px;
      width: 100%;
    }
    button {
      font-size: 20px;
      font-family: 'Open Sans';
      font-weight: 500;
      border-radius: 100px;
      height: 56px;
      width: 205px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      @media screen and (max-width: $mobile-max-width) {
        font-size: 16px;
        height: 48px;
        width: 48%;
      }
    }
    .choose {
      color: $theme-first-background-opposite;
      border: 1px solid $theme-first-background-opposite;
      background: none;
      &:hover {
        border: 1px solid rgb(180, 180, 180);
      }
    }
    .explore {
      color: $cwhite;
      background: $cblue;
      border: none;

      &:hover {
        background: #5057ce;
      }
    }
  }
}

.sectionH1__right {
  @media screen and (max-width: $mobile-max-width) {
    display: none;
  }
  .img__customer {
    position: absolute;
    right: 120px;
    top: -100px;
    width: 440px;
    height: 570px;
    object-fit: contain;
    transform: translate(100px, 0px);
    opacity: 0;
    transition: 1.5s ease;
    transition-delay: 0.3s;
  }

  .img__business {
    position: absolute;
    right: 100px;
    top: -20px;
    width: 430px;
    height: 490px;
    object-fit: cover;
    transform: translate(100px, 0px);
    opacity: 0;
    transition: 1.5s ease;
    transition-delay: 0.3s;
  }
}

.sectionH1__categories {
  max-width: $m1470;
  padding: $m1470p;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 1380px) {
    justify-content: center;
  }
  @media screen and (max-width: 1200px) {
    gap: 8px;
  }

  .category {
    cursor: pointer;
    width: 250px;
    height: 250px;
    position: relative;
    transform: translate(0, 40px);
    opacity: 0;
    transition: 0.7s ease;
    transition-delay: 0.3s;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media screen and (max-width: 1380px) {
      width: 18vw;
      height: 18vw;
    }
    @media screen and (max-width: 1000px) {
      width: 23vw;
      height: 23vw;
    }
    @media screen and (max-width: 720px) {
      width: 30vw;
      height: 30vw;
    }
    @media screen and (max-width: 520px) {
      width: 43vw;
      height: 43vw;
    }
    .category__bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0.73) 78.39%,
        #000 100%
      );
    }
    .category__title {
      color: #fff;
      text-align: center;
      font-family: 'Open Sans';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      height: 40px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      translate: -50%;
      transition: 0.2s;
      @media screen and (max-width: $mobile-max-width) {
        font-size: 16px;
        height: 26px;
        bottom: 15px;
      }
      @media screen and (max-width: 900px) {
        font-size: 14px;
      }
      @media screen and (max-width: 920px) {
        font-size: 13px;
      }
      @media screen and (max-width: 320px) {
        font-size: 12px;
      }
    }
    .sectionH1__explore {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: absolute;
      bottom: 16px;
      left: 50%;
      translate: -50%;
      width: 220px;
      height: 55px;
      text-align: center;
      padding: 10px 15px;
      background: rgba(black, 0.7);
      border-radius: 8px;
      font-size: 16px;
      font-family: 'Open Sans';
      font-weight: 400;
      color: $cwhite;
      backdrop-filter: blur(4px);
      box-shadow: 0px 4px 35px 10px #000;
      transition: 0.2s;
      opacity: 0;
      gap: 5px;
      img {
        position: relative;
        top: 1px;
        width: 12px;
        height: 12px;
      }
      @media screen and (max-width: 1350px) {
        height: 55px;
        width: 120px;
        font-size: 14px;
        bottom: 8px;
      }
      @media screen and (max-width: 320px) {
        font-size: 12px;
      }
    }

    &:hover {
      .category__title {
        bottom: 100px;
        z-index: 4;
      }
      .category__bg {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.73) 78.39%,
          #000 100%
        );
      }
      .sectionH1__explore {
        opacity: 1;
        background: rgba($cblue, 1);
        box-shadow: 0px 4px 45px 20px #000;
        bottom: 22px;
        &:hover {
          background: rgba($cblue, 0.8);
        }
        @media screen and (max-width: $mobile-max-width) {
          bottom: 16px;
        }
      }
    }
  }
}
